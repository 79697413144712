import { api } from '@@/services/api';

export const planApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlan: builder.query<Unpatient.Plan, string>({
      query: (planId) => `plans/${planId}`,
      providesTags: ['Plans'],
    }),
    getMyPlans: builder.query<Unpatient.Plan[], void>({
      query: () => 'plans/mine',
      providesTags: ['Plans'],
    }),
  }),
});

export const { useGetMyPlansQuery, useGetPlanQuery } = planApi;

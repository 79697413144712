import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useCreatePatientMutation } from '@@/services/user';
import Lottie from 'react-lottie';
import logo from '@@/assets/logo.json';
import { useForm, FieldError } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import StandardInput from 'front-ps/lib/src/components/Inputs/StandardInput';
import PhonePickerInput from 'front-ps/lib/src/components/Inputs/PhonePickerInput';
import DatePickerInput from 'front-ps/lib/src/components/Inputs/DatePickerInput';

const NavBar: React.FC = () => {
  return (
    <div className="flex flex-row px-8 py-4 mb-8 justify-between items-center">
      <Link
        to="https://www.unpatient.ai/"
        className="text-white"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src="/logo-white.png" className="w-16 sm:w-48" />
      </Link>
      <div className="flex flex-row items-center space-x-2 sm:space-x-6">
        <Link
          className="text-xs sm:text-lg text-white font-semibold underline"
          to="https://www.unpatient.ai/#service"
          rel="noopener noreferrer"
          target="_blank"
        >
          Le service
        </Link>

        <Link
          className="text-xs sm:text-lg text-white font-semibold underline"
          to="https://www.unpatient.ai/#valeurs"
          rel="noopener noreferrer"
          target="_blank"
        >
          Le fonctionnement
        </Link>

        <Link
          className="text-xs sm:text-lg text-white font-semibold underline"
          to="https://www.unpatient.ai/#offre"
          rel="noopener noreferrer"
          target="_blank"
        >
          L'offre
        </Link>
      </div>
    </div>
  );
};

type SimpleRegisterInputType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate: Date;
};

const schema = yup
  .object({
    firstName: yup.string().trim().required('Veuillez entrer votre prénom'),
    lastName: yup.string().trim().required('Veuillez entrer votre nom'),
    phone: yup.string().required('Veuillez entrer votre numéro de téléphone'),
    email: yup
      .string()
      .email('Veuillez entrer un identifiant valide')
      .required('Veuillez entrer votre email'),
    birthDate: yup.date().required('Veuillez entrer votre date de naissance'),
  })
  .required();

const StartPage: React.FC = () => {
  const [createPatient, { isSuccess }] = useCreatePatientMutation();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SimpleRegisterInputType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const onSubmit = useCallback(
    async (data: SimpleRegisterInputType) => {
      const { birthDate, ...rest } = data;

      return createPatient({
        ...rest,
        medicalRecord: { birthDate },
      });
    },
    [createPatient],
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success('Votre demande à été prise en compte avec succès');
      reset({ phone: '+33' });

      const month = moment().format('YYYY-MM');

      setTimeout(() => {
        window.open(
          `https://calendly.com/unpatient-/bienvenue?month=${month}`,
          '_blank',
        );
      }, 1000);
    }
  }, [reset, isSuccess]);

  return (
    <div className="font-main bg-[url('/start-bg.png')] pb-20 sm:h-screen">
      <NavBar />
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <div className="mx-10 sm:mx-auto bg-gray-100 sm:w-[550px] p-6 sm:p-10 rounded-xl">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: logo,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={80}
            width={80}
          />

          <p className="text-lg sm:text-3xl mt-6">
            Vérifiez que votre santé suit la cadence : Démarrez par
          </p>
          <p className="mt-6">
            <span className="text-xl sm:text-4xl font-bold text-blue-500 bg-white py-2 pr-2 rounded-xl">
              un RDV offert avec l'un de nos docteurs
            </span>
          </p>
        </div>

        <form className="mx-10 sm:mx-auto bg-gray-100 sm:w-[550px] p-6 sm:p-10 rounded-xl space-y-14">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <StandardInput
              register={register}
              id="firstName"
              label="Prénom *"
              type="text"
              placeholder="Votre prénom"
              error={errors.firstName}
            />

            <StandardInput
              register={register}
              id="lastName"
              label="Nom *"
              type="text"
              placeholder="Votre nom"
              error={errors.lastName}
            />

            <StandardInput
              register={register}
              id="email"
              label="Email *"
              type="text"
              placeholder="Votre email"
              error={errors.email}
            />

            <PhonePickerInput
              control={control}
              id="phone"
              error={errors.phone as FieldError}
            />

            <div className="mt-4">
              <DatePickerInput
                id="birthDate"
                control={control}
                label="Date de naissance *"
                placeholder="jj/mm/aaaa"
                showTimeSelect={false}
                error={errors?.birthDate}
                maxDate={new Date()}
              />
            </div>
          </div>

          <button
            className="mx-auto block w-fit p-3 bg-cyan-500 rounded-xl"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            <p className="text-lg text-white">
              Je veux faire le point sur ma santé
            </p>
          </button>
        </form>
      </div>
    </div>
  );
};

export default StartPage;

import React from 'react';

import { useLogoutMutation } from '@@/services/auth';
import { useGetMeQuery } from '@@/services/user';

const MePage: React.FC = () => {
  const { data: me } = useGetMeQuery();
  const [logout] = useLogoutMutation();

  return (
    <div className="relative m-4 w-full">
      <button
        onClick={() => logout()}
        className="p-2 rounded-lg bg-gray-500 mt-4 block ml-auto"
      >
        <p className="font-main text-white">Déconnexion</p>
      </button>

      <div className="mt-8 font-main text-main">
        <p className="text-xl text-center">Site en cours de construction</p>
        <p className="text-center mt-4">
          Veuillez utiliser l'application mobile pour accéder à votre espace
          patient
        </p>
      </div>
    </div>
  );
};

export default MePage;

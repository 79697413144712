import React from 'react';
import * as yup from 'yup';
import StandardInput from 'front-ps/lib/src/components/Inputs/StandardInput';

export const schema = yup
  .object({
    username: yup
      .string()
      .email()
      .required('Veuillez entrer votre identifiant'),
  })
  .required();

const ResetPasswordForm: React.FC<{
  form: any;
}> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <StandardInput
      register={register}
      id="username"
      label="Email (*)"
      placeholder="Email"
      error={errors?.username}
      type="text"
    />
  );
};

export default ResetPasswordForm;

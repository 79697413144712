import React from 'react';
import { Link } from 'react-router-dom';
import { useGetMeQuery } from '@@/services/user';
import AuthenticatedImage from '@@/components/AuthenticatedImage';

const NavBar: React.FC = () => {
  const { data: me } = useGetMeQuery();

  return (
    <div className="bg-cyan-400 flex flex-row px-4 py-3 justify-between items-center">
      <Link to="/home" className="text-white">
        <img src="/logo.png" className="w-28" />
      </Link>

      <Link className="flex flex-row items-center cursor-pointer" to="/me">
        <AuthenticatedImage
          documentId={me?.photoDocumentId}
          placeholder="/user-profile-placeholder.jpg"
          alt={`${me?.firstName} ${me?.lastName}`}
          className="w-6 sm:w-10 bg-white font-main rounded-full"
        />
        <span className="ml-2 mr-1 text-white font-main underline">
          {me?.firstName} {me?.lastName}
        </span>
      </Link>
    </div>
  );
};

export default NavBar;

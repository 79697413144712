import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getTitle, Genders } from '../../constants/medicalRecord';
import { mainColor, smallFontSize, bigFontSize } from '../../constants/pdf';
import { getAge } from '../../utils/date';
import { PrescriptionTypes, translateBiologogyCategory, translateBiologogyDecomination, translateImageryCategory, translateImageryDecomination, } from '../../constants/prescription';
import Header from './Header';
import Footer from './Footer';
const styles = StyleSheet.create({
    page: {},
    date: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: bigFontSize,
        marginRight: '16px',
        textAlign: 'right',
    },
    title: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: bigFontSize,
        marginTop: '16px',
        marginLeft: '16px',
    },
    patientBlock: {
        marginTop: '16px',
        marginLeft: '16px',
    },
    text: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: smallFontSize,
        marginTop: '4px',
    },
    bold: {
        fontFamily: 'Helvetica-Bold',
        fontSize: bigFontSize,
        color: mainColor,
    },
    italic: {
        marginTop: '4px',
        fontFamily: 'Helvetica-Oblique',
        fontSize: smallFontSize,
        color: mainColor,
    },
    prescriptionBlock: {
        margin: '16px',
        marginTop: '24px',
    },
    prescriptionTitle: {
        fontFamily: 'Helvetica-Bold',
        fontSize: bigFontSize,
        color: mainColor,
        marginBottom: '16px',
        textDecoration: 'underline',
    },
    block: {
        marginTop: '12px',
        marginBottom: '12px',
    },
});
const Prescription = ({ prescription, patient, practitioner, signature }) => {
    const prescriptionItems = useMemo(() => {
        switch (prescription?.type) {
            case PrescriptionTypes.BIOLOGY:
                return (_jsxs(View, { style: styles.prescriptionBlock, children: [_jsx(Text, { style: styles.prescriptionTitle, children: "Examen(s) biologique(s) :" }), prescription.biologyItems?.map((item, index) => (_jsxs(View, { style: styles.block, wrap: false, children: [_jsxs(Text, { style: styles.text, children: ["#", index + 1, "/ ", translateBiologogyCategory(item.category), "\u00A0:\u00A0", translateBiologogyDecomination(item.category, item.denomination)] }), _jsx(Text, { style: styles.italic, children: item.comment })] }, index)))] }));
            case PrescriptionTypes.IMAGERY:
                return (_jsxs(View, { style: styles.prescriptionBlock, children: [_jsx(Text, { style: styles.prescriptionTitle, children: "Examen(s) d'imagerie :" }), prescription.imageryItems?.map((item, index) => (_jsxs(View, { style: styles.block, wrap: false, children: [_jsxs(Text, { style: styles.text, children: ["#", index + 1, "/ ", translateImageryCategory(item.category), " :", ' ', translateImageryDecomination(item.category, item.denomination)] }), _jsx(Text, { style: styles.italic, children: item.comment })] }, index)))] }));
            case PrescriptionTypes.FREE:
                return (_jsx(View, { style: styles.prescriptionBlock, children: prescription.freeItems?.map((item, index) => (_jsxs(View, { style: styles.block, wrap: false, children: [_jsx(Text, { style: styles.text, children: item.text }), _jsx(Text, { style: styles.italic, children: item.comment })] }, index))) }));
            default:
                return null;
        }
    }, [prescription]);
    if (!patient?.medicalRecord?.birthDate) {
        return (_jsx(Document, { children: _jsxs(Page, { size: "A4", style: styles.page, children: [_jsx(Header, { practitioner: practitioner }), _jsx(View, { style: { margin: '16px' }, children: !patient?.medicalRecord?.birthDate && (_jsx(Text, { style: styles.text, children: "- Veuillez entrer la date de naissance du patient dans son dossier m\u00E9dical" })) })] }) }));
    }
    return (_jsx(Document, { children: _jsxs(Page, { size: "A4", style: styles.page, children: [_jsx(Header, { practitioner: practitioner }), _jsxs(Text, { style: styles.date, children: ["Le ", moment(prescription?.createdAt).format('DD/MM/YYYY'), ","] }), _jsx(Text, { style: styles.title, children: "Ordonnance \u00E0 destination de :" }), _jsxs(View, { style: styles.patientBlock, children: [_jsxs(Text, { style: styles.bold, children: [getTitle(patient?.medicalRecord?.gender), "\u00A0", patient?.firstName, "\u00A0", patient?.lastName] }), _jsxs(Text, { style: styles.text, children: ["n\u00E9", patient?.medicalRecord?.gender === Genders.F ? '(e) ' : ' ', "le\u00A0", moment(patient?.medicalRecord?.birthDate).format('DD/MM/YYYY'), ",\u00A0", getAge(patient?.medicalRecord?.birthDate), " ans"] })] }), prescriptionItems, _jsx(Footer, { practitioner: practitioner, signature: signature })] }) }));
};
export default Prescription;

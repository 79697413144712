import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BiClinic } from 'react-icons/bi';

const handleLinkStyle = (pathname: string, targetPathName: string) => {
  if (pathname.includes(targetPathName)) {
    return 'text-main underline flex flex-row items-center mb-6';
  } else {
    return 'flex flex-row items-center mb-6';
  }
};

const Sidebar: React.FC = () => {
  const location = useLocation();

  return (
    <div className="hidden sm:block bg-white font-main h-full">
      <div className="flex flex-col p-2">
        <Link className={handleLinkStyle(location.pathname, '/me')} to="/me">
          <BiClinic size={30} />
          <span className="ml-1 text-sm">Mon Espace</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useCreateContactMutation, ContactForm } from '@@/services/contact';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ContactFrom, { schema } from '@@/components/Forms/ContactForm';

const ContactPage: React.FC = () => {
  const form = useForm<ContactForm>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const [createContact, { isSuccess }] = useCreateContactMutation();

  const onSubmit = useCallback(
    (data: ContactForm) => {
      createContact(data);
    },
    [createContact],
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success('Votre demande est prise en compte', {
        position: 'top-right',
      });
      form.reset({
        phone: '+33',
        firstName: '',
        lastName: '',
        email: '',
        city: '',
        message: '',
      });
    }
  }, [form.reset, isSuccess]);

  return (
    <div className="font-main bg-[url('/start-bg.png')] pb-20 sm:h-screen">
      <div className="sm:w-[800px] mx-auto mt-8 bg-gray-50 p-6 rounded-xl">
        <h1 className="font-main font-bold text-2xl text-center text-cyan-500 mb-8">
          J'écris à UnPatient
        </h1>
        <ContactFrom form={form} />

        <button
          className="mt-6 sm:mt-10 mx-auto block w-fit p-3 bg-cyan-500 rounded-xl"
          onClick={form.handleSubmit(onSubmit)}
          disabled={form.formState.isSubmitting}
        >
          <p className="text-lg text-white">Envoyer ma demande</p>
        </button>
      </div>
    </div>
  );
};

export default ContactPage;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller } from 'react-hook-form';
import PI from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const ReactPhoneInput = PI.default || PI;
const PhonePickerInput = ({ control, id, error, label }) => {
    const errorComponent = error?.message && (_jsx("p", { className: "italic font-main text-sm text-red-500 m-1", children: error.message }));
    return (_jsxs("div", { className: "relative", children: [!!label && _jsx("p", { className: "-mt-7 mb-1 font-main text-main", children: label }), _jsx(Controller, { control: control, name: id, rules: { required: true }, render: ({ field }) => (_jsx(ReactPhoneInput, { ...field, country: 'fr', countryCodeEditable: false, inputStyle: {
                        width: '100%',
                        height: '45px',
                        borderRadius: '8px',
                        borderColor: '#E5E7EB',
                    }, buttonStyle: {
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        borderColor: '#E5E7EB',
                    } })) }), errorComponent] }));
};
export default PhonePickerInput;

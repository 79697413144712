import { api } from '@@/services/api';

export type ContactForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  message: string;
};

export const contactApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createContact: builder.mutation<Unpatient.Contact, ContactForm>({
      query: (body) => {
        return {
          url: '/contacts',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useCreateContactMutation } = contactApi;

import { api } from '@@/services/api';

export const documentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyDocuments: builder.query<Unpatient.Document[], void>({
      query: () => 'documents/mine',
      providesTags: ['Documents'],
    }),
    viewDocument: builder.query<string, string>({
      query: (documentId) => ({
        url: `documents/${documentId}/view`,
        responseHandler: async (response) => {
          const contentType = response.headers.get('Content-Type');
          const blob = await response.blob();

          return URL.createObjectURL(
            contentType ? new Blob([blob], { type: contentType }) : blob,
          );
        },
      }),
    }),
  }),
});

export const { useGetMyDocumentsQuery, useViewDocumentQuery } = documentApi;

import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col h-full">
      <Outlet />
      <ScrollRestoration />
    </div>
  );
};

export default Layout;

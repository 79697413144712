import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RootState } from '@@/store';
import {setAccessToken} from '@@/features/auth';

const VanillaPrivateRoute: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!auth.accessToken && !token) {
      navigate('/');
    }

    if (!auth.accessToken && token) {
      dispatch(setAccessToken(token));
    }
  }, [auth.accessToken, location.search, navigate, dispatch]);

  return (
    <div className="flex flex-row flex-grow w-full">
      <Outlet />
    </div>
  );
};

export default VanillaPrivateRoute;

import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { useGetPlanQuery } from '@@/services/plan';
import { CiExport } from 'react-icons/ci';
import Plan from 'front-ps/lib/src/components/PDF/Plan';

const PlanPage: React.FC = () => {
  const { planId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const os = queryParams.get('os');
  const { data: plan, isLoading } = useGetPlanQuery(planId || '', {
    skip: !planId,
  });

  const planPDF = useMemo(
    () => (
      <Plan
        plan={plan}
        patient={plan?.patient}
        practitioner={plan?.practitioner}
      />
    ),
    [plan],
  );

  const handleDownload = useCallback(async () => {
    if (!plan || !planPDF) {
      return;
    }

    const blob = await pdf(planPDF).toBlob();

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = `Plan-${plan.patient?.firstName}-${plan.patient?.lastName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, [plan, planPDF]);

  const downloadButton = useMemo(() => {
    return (
      <button
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
        onClick={handleDownload}
      >
        <CiExport size={22} />
        <span className="ml-1">Exporter</span>
      </button>
    );
  }, [handleDownload, os]);

  useEffect(() => {
    if (os) {
      handleDownload();
    }
  }, [os, handleDownload]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full">
        <p className="mx-auto text-3xl font-main text-main">
          Chargement en cours...
        </p>
      </div>
    );
  }

  if (!plan) {
    return (
      <div className="flex items-center justify-center w-full">
        <p className="mx-auto text-3xl font-main text-main">
          Désolé, nous n'avons pas pu trouver votre plan de soins
        </p>
      </div>
    );
  }

  if (os) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        {downloadButton}
      </div>
    );
  }

  return <PDFViewer className="h-full w-full">{planPDF}</PDFViewer>;
};

export default PlanPage;

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import QRCode from 'qrcode';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { translateSpeciality } from '../../constants/practitioner';
import { mainColor, biggerFontSize, smallFontSize } from '../../constants/pdf';
const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '16px',
        paddingBottom: '10px',
        borderBottom: `1px solid ${mainColor}`,
    },
    logo: {
        width: '130px',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    title: {
        color: mainColor,
        fontFamily: 'Helvetica-Bold',
        fontSize: biggerFontSize,
    },
    speciality: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: smallFontSize,
        marginBottom: '4px',
    },
    text: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: '10px',
    },
    qr: {
        width: '80px',
        height: '80px',
    },
});
const Header = ({ practitioner }) => {
    const speciality = useMemo(() => translateSpeciality(practitioner?.speciality), [practitioner?.speciality]);
    return (_jsxs(View, { style: styles.container, fixed: true, children: [_jsxs(View, { style: styles.col, children: [_jsxs(Text, { style: styles.title, children: ["Dr ", practitioner?.firstName, " ", practitioner?.lastName] }), !!speciality && _jsx(Text, { style: styles.text, children: speciality }), !!practitioner?.rpps && (_jsxs(Text, { style: styles.text, children: ["RPPS : ", practitioner.rpps] })), !!practitioner?.am && (_jsxs(Text, { style: styles.text, children: ["AM : ", practitioner.am] })), !!practitioner?.cdom && (_jsxs(Text, { style: styles.text, children: ["CDOM : ", practitioner.cdom] })), !!practitioner?.secureEmail && (_jsx(Text, { style: styles.text, children: practitioner.secureEmail })), _jsx(Text, { style: styles.text, children: "Tel: +33 7 56 95 47 18" }), _jsx(Text, { style: styles.text, children: "Fax: +43-125-359-628" })] }), _jsx(Image, { style: styles.logo, src: "/logo.png" }), _jsx(Image, { src: QRCode.toDataURL('https://www.unpatient.ai', {
                    margin: 0,
                    errorCorrectionLevel: 'H',
                }), style: styles.qr })] }));
};
export default Header;

import React from 'react';

const FAQPage: React.FC = () => {
  return (
    <div className="flex flex-col p-8 sm:w-[800px] md:w-[1000px] mx-auto font-main text-main">
      <h1 className="text-center font-bold text-xl">FAQ - UnPatient</h1>
      <p className="italic text-sm mt-1 text-center mb-4">
        Dernière mise à jour : 30/05/2024
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        1. Qu'est-ce qu'UnPatient ?
      </h2>
      <p>
        UnPatient est une plateforme de télémédecine qui permet aux utilisateurs
        de bénéficier d'un suivi médical personnalisé directement via leur
        application mobile. Nos services incluent des consultations à distance,
        le suivi des traitements et des prescriptions, ainsi que des conseils de
        santé continus.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        2. Comment puis-je m'inscrire sur UnPatient ?
      </h2>
      <p>
        Pour vous inscrire sur UnPatient, téléchargez notre application mobile
        depuis l'App Store ou Google Play Store. Ouvrez l'application et suivez
        les instructions pour créer un compte en fournissant vos informations
        personnelles et médicales nécessaires.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        3. Quels sont les avantages de l'utilisation d'UnPatient ?
      </h2>
      <p>
        <li>
          Consultations à distance : Accédez à des consultations médicales sans
          quitter votre domicile.
        </li>
        <li>
          Suivi continu : Bénéficiez d'un suivi régulier de votre état de santé.
        </li>
        <li>
          Ordonnances et prescriptions : Recevez vos prescriptions directement
          via l'application.
        </li>
        <li>
          Conseils de santé : Obtenez des conseils personnalisés pour améliorer
          votre bien-être général.
        </li>
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        4. Est-ce que mes informations personnelles sont sécurisées ?
      </h2>
      <p>
        Oui, chez UnPatient, nous prenons la confidentialité et la sécurité de
        vos informations très au sérieux. Toutes les données sont cryptées et
        stockées de manière sécurisée conformément aux réglementations en
        vigueur sur la protection des données.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        5. Comment puis-je prendre rendez-vous avec un médecin ?
      </h2>
      <p>
        Pour prendre rendez-vous, ouvrez l'application UnPatient, accédez à la
        section "Rendez-vous" et sélectionnez le type de consultation dont vous
        avez besoin. Choisissez ensuite une date et une heure disponibles qui
        vous conviennent.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        6. Puis-je obtenir des conseils médicaux en dehors des consultations ?
      </h2>
      <p>
        Oui, UnPatient offre un service de messagerie sécurisé où vous pouvez
        poser des questions et obtenir des conseils de santé de la part de nos
        professionnels de santé entre les consultations.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        7. Comment puis-je supprimer mon compte UnPatient ?
      </h2>
      <p id="delete-account">
        Si vous souhaitez supprimer votre compte UnPatient, suivez ces étapes :
        <li>Ouvrez l'application UnPatient sur votre mobile.</li>
        <li>
          Accédez à la section "Profil" en cliquant sur l'icône de profil en
          haut à droite de l'écran principal.
        </li>
        <li>
          Accèdez à la section "Paramètres" en cliquant sur l'icone en haut à
          droite.
        </li>
        <li>Cliquez sur "Supprimer mon compte".</li>
        <li>Confirmez votre décision en suivant les instructions à l'écran.</li>
      </p>
      <p>
        Note : La suppression de votre compte entraînera la perte définitive de
        toutes vos données et informations médicales stockées dans
        l'application. Assurez-vous de télécharger ou de sauvegarder toutes les
        informations importantes avant de procéder à la suppression.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        8. Qui puis-je contacter pour plus d'aide ?
      </h2>
      <p>
        Si vous avez des questions ou besoin d'assistance supplémentaire, vous
        pouvez contacter notre service client via l'application en accédant à la
        section "Aide" ou en envoyant un e-mail à notre support à l'adresse
        suivante : anne-laure.rousseau@unpatient.fr
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        9. Quels types de consultations sont disponibles sur UnPatient ?
      </h2>
      <p>
        UnPatient propose une variété de consultations médicales, y compris,
        mais sans s'y limiter, des consultations générales, des consultations
        spécialisées, des suivis de traitement, et des consultations de santé
        mentale.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        10. Puis-je accéder à mes antécédents médicaux via l'application ?
      </h2>
      <p>
        Oui, vous pouvez accéder à vos antécédents médicaux et à vos documents
        de santé en vous rendant dans la section "Dossier médical" de
        l'application. Vous pouvez y consulter vos précédentes consultations,
        prescriptions, et autres documents pertinents.
      </p>

      <div className="h-10" />
    </div>
  );
};

export default FAQPage;

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { mainColor, biggerFontSize, bigFontSize } from '../../constants/pdf';
const styles = StyleSheet.create({
    container: {
        margin: '16px 16px 16px auto',
    },
    doctorName: {
        fontFamily: 'Helvetica-Bold',
        fontSize: biggerFontSize,
        color: mainColor,
    },
    italic: {
        fontFamily: 'Helvetica-Oblique',
        fontSize: bigFontSize,
        color: mainColor,
    },
    signature: {
        width: '150px',
        height: 'auto',
    },
});
const Footer = ({ practitioner, signature }) => {
    if (!practitioner) {
        return null;
    }
    return (_jsxs(View, { style: styles.container, fixed: true, children: [_jsxs(Text, { style: styles.doctorName, children: ["Dr ", practitioner.firstName, " ", practitioner.lastName] }), _jsx(Text, { style: styles.italic, children: "Signature s\u00E9curis\u00E9e" }), !!signature && _jsx(Image, { style: styles.signature, src: signature })] }));
};
export default Footer;

import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { useGetReferralQuery } from '@@/services/referral';
import { useViewDocumentQuery } from '@@/services/document';
import { CiExport } from 'react-icons/ci';
import Referral from 'front-ps/lib/src/components/PDF/Referral';

const PrescriptionPage: React.FC = () => {
  const { referralId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const os = queryParams.get('os');

  const { data: referral, isLoading } = useGetReferralQuery(referralId || '', {
    skip: !referralId,
  });

  const { data: signature } = useViewDocumentQuery(
    referral?.practitioner?.signatureDocumentId || '',
    {
      skip: !referral?.practitioner?.signatureDocumentId,
    },
  );

  const referralPDF = useMemo(
    () => (
      <Referral
        referral={referral}
        patient={referral?.patient}
        practitioner={referral?.practitioner}
        signature={signature}
      />
    ),
    [referral, signature],
  );

  const handleDownload = useCallback(async () => {
    if (!referral || !referralPDF) {
      return;
    }

    const blob = await pdf(referralPDF).toBlob();

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = `Adressage-${referral.patient?.firstName}-${referral.patient?.lastName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, [referral, referralPDF]);

  const downloadButton = useMemo(() => {
    return (
      <button
        className="bg-cyan-400 rounded-lg p-2 flex flex-row items-center text-white h-fit"
        onClick={handleDownload}
      >
        <CiExport size={22} />
        <span className="ml-1">Exporter</span>
      </button>
    );
  }, [handleDownload]);

  useEffect(() => {
    if (os) {
      handleDownload();
    }
  }, [os, handleDownload]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full">
        <p className="mx-auto text-3xl font-main text-main">
          Chargement en cours...
        </p>
      </div>
    );
  }

  if (!referral) {
    return (
      <div className="flex items-center justify-center w-full">
        <p className="mx-auto text-3xl font-main text-main">
          Désolé, nous n'avons pas pu trouver votre lettre d'adressage
        </p>
      </div>
    );
  }

  if (os) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        {downloadButton}
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <PDFViewer className="h-full w-full">{referralPDF}</PDFViewer>

      <div className="block sm:hidden absolute right-4 top-8">
        {downloadButton}
      </div>
    </div>
  );
};

export default PrescriptionPage;

import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="flex flex-col p-8 sm:w-[800px] md:w-[1000px] mx-auto font-main text-main">
      <h1 className="text-center font-bold text-xl">
        UNPATIENT – Politique de confidentialité
      </h1>
      <p className="italic text-sm mt-1 text-center">
        Dernière mise à jour : 22/05/2024
      </p>
      <p className="mt-4">
        Bienvenue sur UNPATIENT, une plateforme de téléconsultation médicale
        dédiée au suivi du patient. Nous prenons très au sérieux la
        confidentialité et la sécurité de vos informations personnelles. Cette
        politique de confidentialité décrit comment nous recueillons, utilisons,
        partageons et protégeons vos informations personnelles.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        1. Informations que Nous Recueillons
      </h2>
      <p>
        Nous recueillons plusieurs types d'informations pour fournir et
        améliorer nos services :
        <li>
          Informations Personnelles : Nom, adresse, numéro de téléphone, adresse
          e-mail, date de naissance, sexe, etc
        </li>
        <li>
          Informations Médicales : Historique médical, prescriptions,
          diagnostics, notes des consultations, etc.
        </li>
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        2. Utilisation de Vos Informations
      </h2>
      <p>
        Nous utilisons vos informations pour :
        <li>Fournir et gérer nos services de téléconsultation.</li>
        <li>
          Communiquer avec vous concernant vos consultations et rendez-vous.
        </li>
        <li>Améliorer nos services et personnaliser votre expérience.</li>
        <li>
          Assurer la sécurité de notre plateforme et prévenir les fraudes.
        </li>
        <li>Respecter les obligations légales et réglementaires.</li>
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        3. Partage de Vos Informations
      </h2>
      <p>
        Nous ne partageons vos informations personnelles qu'avec :
        <li>
          Professionnels de Santé : Pour fournir des soins médicaux. •
          Partenaires de Service : Pour traiter les paiements, héberger notre
          plateforme, etc.
        </li>
        <li>
          Autorités Légales : Lorsque cela est requis par la loi ou pour
          protéger nos droits et ceux de nos utilisateurs.
        </li>
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        4. Sécurité de Vos Informations
      </h2>
      <p>
        Nous mettons en œuvre des mesures de sécurité techniques et
        organisationnelles appropriées pour protéger vos informations contre
        l'accès non autorisé, la divulgation, l'altération ou la destruction.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">5. Vos Droits </h2>
      <p>
        Vous avez le droit de :
        <li>
          Accéder à vos informations personnelles et en demander une copie.
        </li>
        <li>
          Corriger vos informations personnelles si elles sont inexactes ou
          incomplètes.
        </li>
        <li>
          Demander la suppression de vos informations personnelles, sous réserve
          des obligations légales et réglementaires.
        </li>
        <li>
          Vous opposer au traitement de vos informations personnelles dans
          certaines circonstances.
        </li>
        <li>
          Retirer votre consentement à tout moment lorsque le traitement est
          basé sur votre consentement. Pour exercer vos droits, veuillez nous
          contacter à anne-laure.rousseau@unpatient.fr
        </li>
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        6. Conservation des Informations
      </h2>
      <p>
        Nous conservons vos informations personnelles aussi longtemps que
        nécessaire pour fournir nos services et respecter nos obligations
        légales. Lorsque vos informations ne sont plus nécessaires, nous les
        supprimons de manière sécurisée.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">
        7. Modifications de la Politique de Confidentialité
      </h2>
      <p>
        Nous pouvons mettre à jour cette politique de confidentialité de temps
        en temps. Nous vous informerons de tout changement important en publiant
        la nouvelle politique sur notre site web et en vous envoyant une
        notification.
      </p>
      <h2 className="font-bold mt-4 text-lg mb-1">8. Contact</h2>
      <p>
        Si vous avez des questions ou des préoccupations concernant cette
        politique de confidentialité, veuillez nous contacter via
        anne-laure.rousseau@unpatient.fr
      </p>

      <div className="h-10" />
    </div>
  );
};

export default PrivacyPolicyPage;

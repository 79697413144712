import { api } from '@@/services/api';

export const referralApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyReferrals: builder.query<Unpatient.Referral[], void>({
      query: () => 'referrals/mine',
      providesTags: ['Referrals'],
    }),
    getReferral: builder.query<Unpatient.Referral, string>({
      query: (referralId) => `referrals/${referralId}`,
      providesTags: ['Referrals'],
    }),
  }),
});

export const { useGetMyReferralsQuery, useGetReferralQuery } = referralApi;

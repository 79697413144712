import { api } from '@@/services/api';

export const prescriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyPrescriptions: builder.query<Unpatient.Prescription[], void>({
      query: () => 'prescriptions/mine',
      providesTags: ['Prescriptions'],
    }),
    getPrescription: builder.query<Unpatient.Prescription, string>({
      query: (prescriptionId) => `prescriptions/${prescriptionId}`,
      providesTags: ['Prescriptions'],
    }),
  }),
});

export const { useGetMyPrescriptionsQuery, useGetPrescriptionQuery } =
  prescriptionApi;

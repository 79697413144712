import React from 'react';
import * as yup from 'yup';
import { FieldError } from 'react-hook-form';
import { AiOutlineMail } from 'react-icons/ai';
import { TiPhoneOutline } from 'react-icons/ti';
import StandardInput from 'front-ps/lib/src/components/Inputs/StandardInput';
import PhonePickerInput from 'front-ps/lib/src/components/Inputs/PhonePickerInput';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const schema = yup
  .object({
    firstName: yup.string().required('Veuillez entrer votre prénom'),
    lastName: yup.string().required('Veuillez entrer votre nom'),
    email: yup
      .string()
      .email('Veuillez entrer un email valide')
      .required('Veuillez entrer votre email'),
    phone: yup
      .string()
      .matches(phoneRegExp, 'Veuillez entrer un numéro de téléphone valide')
      .required('Veuillez entrer votre numéro de téléphone'),
    city: yup.string().required('Veuillez entrer votre ville'),
    message: yup.string().required('Veuillez entrer votre message'),
  })
  .required();

const StandardHeader = () => (
  <>
    <h1 className="font-main font-bold text-main text-2xl text-center">
      Nous contacter
    </h1>
    <ul className="font-main text-main text-base text-center mt-2">
      <li className="flex flex-row items-center justify-center">
        <TiPhoneOutline />
        <strong className="ml-1">06 47 38 07 09</strong>
      </li>
      <li className="flex flex-row items-center justify-center">
        <AiOutlineMail />
        <strong className="ml-1">contact@spor100t.com</strong>
      </li>
      <li className="mt-4">
        Vous pouvez aussi remplir le <strong>formulaire</strong> suivant.
      </li>
    </ul>
    <p className="font-main text-main text-sm italic text-center mt-1 mb-10">
      Nous vous recontacterons sous 72h
    </p>
  </>
);

const ContactForm: React.FC<{ form: any }> = ({ form }) => {
  const {
    register,
    control,
    formState: { errors },
  } = form;

  return (
    <form autoComplete="off" className="space-y-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <StandardInput
          register={register}
          id="firstName"
          label="Prénom *"
          type="text"
          placeholder="Votre prénom"
          error={errors.firstName}
        />

        <StandardInput
          register={register}
          id="lastName"
          label="Nom *"
          type="text"
          placeholder="Votre nom"
          error={errors.lastName}
        />

        <StandardInput
          register={register}
          id="email"
          label="Email *"
          type="text"
          placeholder="Votre email"
          error={errors.email}
        />

        <PhonePickerInput
          control={control}
          id="phone"
          error={errors.phone as FieldError}
        />
      </div>

      <StandardInput
        register={register}
        id="city"
        label="Ville de résidence *"
        type="text"
        placeholder="Votre ville de résidence"
        error={errors.city}
      />

      <StandardInput
        register={register}
        id="message"
        label="Message *"
        type="textarea"
        placeholder="Votre message"
        error={errors.message}
      />
    </form>
  );
};

export default ContactForm;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { mainColor, smallFontSize, bigFontSize } from '../../constants/pdf';
import CalendarIcon from './CalendarIcon';
import PinIcon from './PinIcon';
import TargetIcon from './TargetIcon';
import Header from './Header';
const styles = StyleSheet.create({
    page: {},
    title: {
        color: mainColor,
        fontFamily: 'Helvetica',
        fontSize: bigFontSize,
        margin: '8px 16px',
        textAlign: 'center',
    },
    bold: {
        fontFamily: 'Helvetica-Bold',
        fontSize: bigFontSize,
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        margin: '16px',
        color: mainColor,
    },
    month: {
        width: '130px',
        paddingRight: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRight: `2px solid ${mainColor}`,
        marginTop: '2px',
    },
    monthText: {
        marginLeft: '2px',
        fontFamily: 'Helvetica-Bold',
        fontSize: smallFontSize,
    },
    recommendations: {
        width: '500px',
        paddingLeft: '25px',
    },
    recommendation: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '14px',
        backgroundColor: '#e0f2fe',
        padding: '15px 30px 15px 10px',
        borderRadius: '15px',
    },
    description: {
        overflowWrap: 'break-word',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    descriptionText: {
        marginLeft: '4px',
        fontFamily: 'Helvetica-Bold',
        fontSize: smallFontSize,
    },
    objective: {
        marginTop: '10px',
        overflowWrap: 'break-word',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    objectiveText: {
        marginLeft: '4px',
        fontSize: smallFontSize,
        fontFamily: 'Helvetica',
    },
});
const Plan = ({ plan, patient, practitioner }) => {
    const maxRecos = useMemo(() => {
        if (!plan) {
            return 0;
        }
        return plan.program.reduce((prev, cur) => {
            return Math.max(prev, cur.recommendations.length);
        }, 0);
    }, [plan]);
    return (_jsx(Document, { children: _jsxs(Page, { size: "A4", style: styles.page, wrap: maxRecos < 5, children: [_jsx(Header, { practitioner: practitioner }), _jsxs(Text, { style: styles.title, children: ["Plan de soins destin\u00E9 \u00E0\u00A0", _jsxs(Text, { style: styles.bold, children: [patient?.firstName, " ", patient?.lastName] }), ", \u00E9mis le ", moment(plan?.createdAt).format('DD/MM/YYYY')] }), plan?.program?.map((program, i) => {
                    const month = moment(program.month, 'MM/YYYY').format('MMM YYYY');
                    return (_jsxs(View, { style: styles.section, children: [_jsxs(View, { style: styles.month, children: [_jsx(CalendarIcon, { color: mainColor, size: 24 }), _jsx(Text, { style: styles.monthText, children: month })] }), _jsx(View, { style: styles.recommendations, children: program.recommendations.map((reco, k) => (_jsxs(View, { style: styles.recommendation, wrap: false, children: [_jsxs(View, { style: styles.description, children: [_jsx(PinIcon, { color: mainColor, size: 20 }), _jsx(Text, { style: styles.descriptionText, children: reco.description })] }), _jsxs(View, { style: styles.objective, children: [_jsx(TargetIcon, { color: mainColor, size: 20 }), _jsx(Text, { style: styles.objectiveText, children: reco.objective })] })] }, k))) })] }, i));
                })] }) }));
};
export default Plan;
